import {useCallback} from "react"
import {push} from "./history"

const Link = ({children, ...props}) => {
    const {href} = props
    const handleClick = useCallback(e => {
        e.preventDefault()
        push(href)
    }, [href])
    return (
            <a {...props} onClick={handleClick}>{children}</a>
    )
}
export default Link
