import {useState, useEffect} from "react"
import {routes} from "./config"
import {historyAddHandler} from "./history"

const Router = () => {
    const [pathname, setPathname] = useState(document.location.pathname)
    const Comp = routes[pathname]??routes['/']
    useEffect(() => {
        historyAddHandler(() => {
            setPathname(document.location.pathname)
        })
    }, [])
    return (<Comp pathname={pathname}/>)
}
export default Router
