//import {Fragment} from "react"
import "./Page.css"
import text from "./text.json"
import {html} from "./html"
/*
const renderTag = line => {
    const [Tag, attrs, text] = line
    return <Tag {...attrs}>{render(text)}</Tag>
}
const render = line => {
    //console.log(line)
    if(typeof line==="string") return line
    if (typeof line[0]==="string") return renderTag(line)
    if(Array.isArray(line[0])) return (
            <>{line.map((l, i) => <Fragment key={i}>{render(l)}</Fragment>)}</>
    )
    return null
}*/
const Page = ({pathname}) => {
    const data = text[pathname]
    if(!data) return null

    return (
            <div>
            <h1>{data.title}</h1>
            {data.sections.map((section, i) => (
                    <section key={i}>
                    {section.title && <h2>{section.title}</h2>}
                {section.text.map((line, i) => <p key={i}>{
                    typeof line==="string"
                        ?line
                        :html(line)
                }</p>)}
                    </section>
            ))}
            </div>
    )
}
export default Page
