import './App.css';
import Header from "./Header"
import Router from "./Router"
import Footer from "./Footer"

function App() {
  return (
          <div className="app">
          <Header/>
          <main>
          <div className="main-content"><div className="content-inner">
          <Router/>
          </div></div>
          <div className="main-illustration"><img src="/img/img-01.jpg" alt="illustration"/></div>
          </main>
          <Footer/>
    </div>
  );
}

export default App;


