import Home from "./Home"
import Page from "./Page"
import Gdpr from "./Gdpr"
import Contact from "./Contact"

export const menu = [
    {path: '/', label: 'Acasă'},
    {path: '/tipuri-de-pensii', label: 'Tipuri de pensii'},
    {path: '/ajutor-de-deces', label: 'Ajutor de deces'},
    {path: '/gdpr', label: 'GDPR'},
    {path: '/contact', label: 'Contact'},
]
export const routes = {
    '/': Home,
    '/tipuri-de-pensii': Page,
    "/tipuri-de-pensii/pensie-pentru-limita-de-varsta": Page,
    "/tipuri-de-pensii/pensie-anticipata": Page,
    "/tipuri-de-pensii/pensie-anticipata-partiala": Page,
    "/tipuri-de-pensii/pensie-de-urmas": Page,
    "/tipuri-de-pensii/pensie-de-invaliditate": Page,
    '/ajutor-de-deces': Page,
    '/gdpr': Gdpr,
    '/contact': Contact
}
