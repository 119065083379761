import text from "./text.json"

const Contact = () => 
      <div>
      <h1>Contact</h1>
      <h2>{text.name.org}</h2>
      <h3>{text.name.line2}</h3>
      <div>{text.address.map((line, i) => <div key={i}>{line}</div>)}</div>
</div>

export default Contact
