import {Fragment} from "react"
import Link from "./Link"

const tags = {Link}

const renderTag = line => {
    const [Tag, attrs, text] = line
    if(tags[Tag]) {
        const T = tags[Tag]
        return <T {...attrs}>{render(text)}</T>
    }
    return <Tag {...attrs}>{render(text)}</Tag>
}
const render = line => {
    //console.log(line)
    if(typeof line==="string") return line
    const isList = line.reduce((acc, e) => acc || Array.isArray(e), false)
    if(isList) {
        return (
                <>{line.map((l, i) => <Fragment key={i}>{render(l)}</Fragment>)}</>
        )
    }
    if (typeof line[0]==="string") return renderTag(line)
    //if(Array.isArray(line[0])) 
    return null
}
export const html = text => render(text)

