let handler
export const historyAddHandler = h => {
    handler=h
}

window.onpopstate = () => {
    if(!handler) return
    handler(document.location.pathname)
}

export const push = pathname => {
    window.history.pushState({ pathname }, "", pathname)
    if(!handler) return
    handler(document.location.pathname)
}
