import "./Home.css"
import text from "./text.json"
import {html} from "./html"

const Home = () =>
      <div>{text.home.presentation.map((line, i) => <p key={i}>{html(line)}</p>)}</div>
export default Home


/*
  {
  return (
  <div className="presentation">
  <div className="text"><div>{text.home.presentation.map((line, i) => <p key={i}>{line}</p>)}</div></div>
  <div className="illustration"><img src="/img/img-01.jpg" alt="presentation"/></div>
  </div>
  )
  }*/
