import './Header.css';
//import text from "./text.json"
import {menu} from "./config"
import Link from "./Link"

const Header = () => {
    return (
            <header className="app-header">
            <div className="logo-wrap">
            <img src="/img/logo-01.png" className="logo" alt="Casa de pensii" />
            <div>
            <img src="/img/logo-1.svg" alt="" style={{width: 400}}/>
            </div>
            </div>
            <div className="main-menu">
            <nav role="menu">
            {menu.map((menuItem, i) =>
                menuItem.type==="download"
                    ?<a key={i} href={menuItem.path}>{menuItem.label}</a>
                    :<Link key={i} href={menuItem.path}>{menuItem.label}</Link>
            )}
        </nav>
            </div>
            </header>
    )
}
export default Header

