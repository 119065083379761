const Gdpr = () => {
    return (
            <div>
            <h1 className="title">INFORMARE</h1>
            <h2 className="title">privind prelucrarea datelor cu caracter personal - asigurat</h2>

            <p><strong>Casa de Pensii și Ajutorare a Cultului Creștin Penticostal</strong>, cu sediul în București, Bulevardul Tineretului nr. 29, Bl. 19, Sc. A, etaj 10, ap. 42, sect. 4, e-mail: <a href="mailto:office@pensiicp.ro">office@pensiicp.ro</a>, tel: 0213.300.326, reprezentată legal prin JOLȚA Ilie - Președinte, în calitate de operator de date cu caracter personal, în baza art. 13 din REGULAMENTUL (UE) 679/2016 AL PARLAMENTULUI EUROPEAN,</p>

            <p>Vă informăm că vom colecta și prelucra, respectiv înregistra, organiza, structura, stoca, adapta sau modifica, extrage, consulta, utiliza, divulga prin transmitere, disemina sau pune la dispoziție în orice alt mod, alinia sau combina, restricționa, șterge sau distruge, datele dvs. cu caracter personal, respectiv:</p>
            <ul>
            <li>nume, prenume,</li>
            <li>sexul</li>
            <li>data și locul nașterii</li>
            <li>cetățenia</li>
            <li>semnătura</li>
            <li>numărul dosarului de pensie</li>
            <li>numărul deciziei de pensie</li>
            <li>telefon/fax</li>
            <li>adresă de domiciliu/reședință/corespondență</li>
            <li>adresă e-mail</li>
            <li>Seria și numărul actului de identitate/pașaportului</li>
            <li>date din acte de stare civilă</li>
            <li>situație familială</li>
            <li>situație militară</li>
            <li>certificat de naștere</li>
            <li>certificat de deces</li>
            <li>certificat medical (pensie invaliditate)</li>
            <li>profesie</li>
            <li>loc de muncă</li>
            <li>formare profesională – diplome, studii Date bancare.</li>
            <li>Venituri asigurate în alte sisteme de asigurări sociale.</li>
            </ul>
            <p>în următoarele scopuri:</p>
            <ul>
            <li>Acordarea pensiilor i altor drepturi de asigurări sociale contribuabililor sistemului propriu de pensii: pensia pentru limita de vârsta / anticipată / anticipată parțial, pensia de invaliditate, pensia de urmaș, ajutor de deces, indemnizații reparatorii și alte drepturi stabilite conform Regulamentului de Organizare și funcționare a Casei de Pensii.</li>
            <li>Certificarea stagiului de cotizare, a punctajului anual și a punctajului cumulat pentru perioada de cotizare.</li>
            </ul>
            
            <h3>Temeiul juridic al prelucrării datelor dvs. cu caracter personal îl reprezintă:</h3>
            <ul>
            <li>executarea unui contract (Art. 6 alin. (1) lit. (b) din Regulament),</li>
            <li>prelucrare necesară conformării obligației legale (Art. 6 alin. (1) lit. (c) din Regulament).</li>
            <li>prelucrarea este necesară în scopul intereselor legitime urmărite de operator (Art. 6 alin 1 lit (f) din Regulament).</li>
            </ul>
            <p>Vă informăm că destinatarii datelor dvs. cu caracter personal sunt angajații subscrisei precum și instituțiile statului.</p>
            <p>Nu intenționăm transferarea acestor date către o altă companie din interiorul Uniunii Europene/din afara Uniunii Europene (ex. societate de marketing și publicitate). </p>
            <p>Datele vor fi prelucrate și stocate până la încetarea calității de asigurat al beneficiarului și/sau a urmașilor acestuia.</p>
            <p>Vă informăm că aveți urmatoarele drepturi:</p>
            <ul>
            <li>dreptul de a fi informat despre cum colectăm și folosim aceste date (informații pe care le gasiți în această informare);</li>
            <li>dreptul de a avea acces la informațiile pe care le deținem;</li>
            <li>dreptul de a rectifica datele dacă sunt incorecte/inexacte/incomplete;</li>
            <li>dreptul de a ne cere ștergerea datelor;</li>
            <li>dreptul de a primi o copie de pe orice informații deținem;</li>
            <li>dreptul de opoziție la prelucrarea datelor personale în scopurile enumerate anterior.</li>
            </ul>
            <p>În exercitarea dreptului la rectificarea datelor, vă recomandăm să ne notificați fiecare modificare a datelor dvs. în cel mai scurt timp posibil, astfel încât acestea să fie actualizate în mod constant.</p>
            <p>Drepturile de mai sus se exercită prin formularea unei cereri scrise, semnate și datate, transmise astfel:</p>
            <ul>
            <li>Prin poștă/curier la adresa noastră poștală din București, Bulevardul Tineretului nr. 29, Bl. 19, Sc. A, etaj 10, ap. 42, sect. 4, sau</li>
            <li>Prin e-mail la adresa de e-mail <a href="mailto:office@pensiicp.ro">office@pensiicp.ro</a></li>
            </ul>
            <p>Cererea va fi analizată de subscrisa în fiecare situație și vă vom răspunde neîntârziat. În situația admiterii acesteia, vom facilita exercitarea drepturilor dvs. solicitate, iar în situația respingerii, vă vom informa asupra motivelor refuzului. Dacă sunteți nemulțumit, aveți posibilitatea de a depune o plângere la Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal ale cărei date de contact le găsiți la adresa <a href="https://www.dataprotection.ro" target="_blank" rel="noreferrer">www.dataprotection.ro</a> și de a vă adresa justiției.</p>
<p>Organizatia nu folosește procese decizionale automatizate și nu crează profiluri în legătură cu datele dvs. personale.</p>
<p>Am stabilit măsuri tehnice și procedurale, pentru a proteja și pentru a asigura confidențialitatea, integritatea și accesibilitatea datelor dvs. cu caracter personal prelucrate; vom preveni utilizarea sau accesul neautorizat și vom preveni încălcarea securității datelor cu caracter personal, în conformitate cu legislația în vigoare. </p>
            </div>
    )
}

export default Gdpr
