import "./Footer.css"
import text from "./text.json"

const Footer = () => 
      <footer>
      <div className="contact">
      <img src="/img/logo-white-01.png" alt="logo"/>
      <h2>{text.name.org}</h2>
      <h3>{text.name.line2}</h3>
      <div>{text.address.map((line, i) => <div key={i}>{line}</div>)}</div>
      </div>
      </footer>

export default Footer
